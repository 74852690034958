import { Component, Vue, Watch as watch} from 'nuxt-property-decorator';
import { mapState} from 'vuex';
import { faYandex, faOdnoklassniki, faVk, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { showErrorFlash, showFlash } from '~/lib/flash';
import OtpInput from "@bachdgvn/vue-otp-input";

@Component({
    components: {
        btn: require("~/components/form/button").default,
        formField: require('~/components/form/form-field').default,
        captcha: require('~/components/common/captcha').default,
        yandexButton: require('~/components/login/yandex-button').default,
        OtpInput
    },
    computed: mapState(['region']),
    fetchOnServer: false,
})
export default class PhoneLoginFormComponent extends Vue {

    login = '';
    password = '';

    public loading = false;

    private region: Store.CurrentRegion;

    stage = false;

    target: number | null = null;

    timer = null;
    displayTime = '';
    invalid = 'da';
    showCaptcha = false;

    public async sendForm() {
        if (this.loading || !this.login) {
            return;
        }

        this.loading = true;
        this.showCaptcha = true;

    }

    public async SendPhoneRequest(token: string) {
        const data = await this.$store.dispatch('RestRequest', {
            url: '/auth/get-phone-code',
            params: {
                phone: this.login,
                token
            }
        });

        this.resetTimer(data.expires);

        this.stage = true;
        this.loading = false;
    }

    public async auth(code) {
        if (this.loading) {
            return;
        }

        try {
            this.loading = true;

            await this.$store.dispatch('UserLoginByPhone', {
                phone: this.login,
                code: code,
            });


            showFlash({
                text: 'Успешная авторизация',
                type: 'success',
                timeout: 3000,
            });
            this.close();
            this.changePhone();

        } catch (error: any) {
            if (!error.handled) {
                showErrorFlash('Неизвестная ошибка');
            }
        } finally {
            this.loading = false;
        }
    }

    changePhone() {
        this.stage = false;
        this.login = '';
        this.invalid = 'da';
    }

    mounted() {
        this.timer = setInterval(() => {
            const t = Math.trunc((this.target - Date.now()) / 1000);
        
            if (t <= 0) {
                this.displayTime = '';
            } else {
                this.displayTime = `(${Math.trunc(t / 60)}:${Math.trunc(t % 60).toString().padStart(2, '0')})`;
            }
        }, 1000);
    }

    onBeforeUnmount() {
        clearInterval(this.timer);
    }

    public close() {
        this.$store.commit('setLoginFormState', false);
    }

    resetTimer(time: string) {
        this.target = new Date(time).getTime()
        this.displayTime = '(5:00)'
    }

    get phonePlaceholder(): string {
        return `${this.region.country.phoneStart} (`;
    }

}
