<template>
    <div id="yandex-login"></div>
</template>

<script>
import { loadYandexLoginScript } from '~/lib/yandex-oauth';


export default {
    mounted: async() => {
        await loadYandexLoginScript();

        const queryParams = {
            client_id: 'a9893af387e8401ab9291e159e3dc9de',
            response_type: 'token',
            redirect_uri: 'https://triya.ru/oauth/yandex',
        };

        // @ts-ignore
        const handler = await window.YaAuthSuggest.init(queryParams, 'https://triya.ru/oauth/yandex', {
            view: "button",
            parentId: "yandex-login",
            buttonSize: 'm',
            buttonView: 'main',
            buttonTheme: 'light',
            buttonBorderRadius: "5",
            buttonIcon: 'ya',
        });

        if (typeof handler.handler === 'function') {
            handler.handler();
        }
    }
}

</script>